@import 'Variables.less';
@import 'Standard/Mixins.less';

@media @media-rd-smartphone-landscape {
  body {
    overflow: hidden;
    width: 100vw;
    height: 100vh;
  }

  .app-tripunit {
    > .wrapper {
      > .pan {
        background-color: @color-tripunit;
        padding: 0;
        position: fixed;
        inset: 0;
        z-index: 100;
        max-width: 100vw;
        width: 100vw;
        height: 100vh;
        overflow: scroll;
        margin: 0;

        > .wrap-tripunit {
          height: 100vh;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0;

          > .tripunit {
            left: auto;
            .transform (translateX(0));
          }

          > .zoom {
            display: block;

            .icon {
              position: absolute;
              bottom: 30px;
              right: 30px;
              color: @dft-font-color;
              .font-size(24px, 0);
              z-index: 1;

              &.xt-fullout {
                display: block;
              }

              &.xt-full {
                display: none;
              }
            }

            .message {
              position: absolute;
              inset: 0;
              .color-rgba(@color-white, 0.3);
              background-color: @color-rgba;
              padding: 10px;
              text-align: center;
              display: flex;
              justify-content: center;
              align-items: center;
              .opacity(0);
              visibility: hidden;
              .transition(@dft-transition);

              .smartphone {
                .font-size(60px, 0);
                color: @color-white;
                .color-rgba(@color-black, 0.35);
                text-shadow: 0 0 5px @color-rgba;
              }

              &[data-show='true'] {
                .opacity(1);
                visibility: visible;

                .smartphone {
                  .animation(rotation 1.5s infinite linear);

                  @-webkit-keyframes rotation {
                    .rotationframes;
                  }

                  @-moz-keyframes rotation {
                    .rotationframes;
                  }

                  @-ms-keyframes rotation {
                    .rotationframes;
                  }

                  @-o-keyframes rotation {
                    .rotationframes;
                  }

                  @keyframes rotation {
                    .rotationframes;
                  }

                  .rotationframes() {
                    0% {
                      -webkit-transform: rotate(90deg);
                      transform: rotate(90deg);
                    }

                    15% {
                      -webkit-transform: rotate(90deg);
                      transform: rotate(90deg);
                    }

                    40% {
                      -webkit-transform: rotate(0deg);
                      transform: rotate(0deg);
                    }

                    60% {
                      -webkit-transform: rotate(0deg);
                      transform: rotate(0deg);
                    }

                    75% {
                      -webkit-transform: rotate(90deg);
                      transform: rotate(90deg);
                    }

                    100% {
                      -webkit-transform: rotate(90deg);
                      transform: rotate(90deg);
                    }
                  }
                }
              }
            }
          }

          > .edit-data {
            display: block;
            position: absolute;
            top: 30px;
            right: 30px;

            .notify {
              position: absolute;
              top: -5px;
              right: -5px;
              width: 10px;
              height: 10px;
              background-color: @color-red;
              .border-radius(10px);
              border: 2px solid @color-tripunit;
            }

            .icon {
              color: @dft-font-color;
              .font-size(24px, 0);
              z-index: 1;
            }
          }
        }
      }

      .wrap-tap {
        position: fixed;
        z-index: 1000;
        display: block;
        inset: 0;
        .color-rgba(@color-black, 0.5);
        background-color: @color-rgba;
        visibility: hidden;
        .opacity(0);
        .transition(@dft-transition);
      }

      .data-edit {
        padding: 20px;
        position: fixed;
        inset: 0 auto 0 -100%;
        max-height: 100vh;
        width: 50vw;
        min-width: 300px;
        max-width: ~'calc(100% - 30px)' !important;
        overflow-y: auto;
        overflow-x: hidden;
        background-color: @color-white;
        z-index: 1001;
        .border-box();
        .transition(@dft-transition);

        .settings {
          gap: 20px;

          .parameters {
            width: 100%;

            > .form {
              > .fieldset {
                flex-direction: column;
                gap: 20px;
              }
            }
          }

          > .tabel-settings {
            width: ~'calc(100% + 40px)';
            margin-left: -20px;
            margin-bottom: 20px;

            .data-table {
              width: 100%;
              margin: 0;

              .table {
                .thead {
                  display: none;
                }

                .tbody {
                  display: block;
                }

                .row {
                  display: block;
                  border-bottom: 1px solid @color-grayxxl;
                  padding: 15px 20px 15px 50px;
                  position: relative;

                  .cel {
                    display: block;
                    border: 0;
                    padding: 0;
                    margin-bottom: 8px;

                    &[data-type='version'] {
                      position: absolute;
                      left: 20px;
                      top: 15px;
                    }

                    &:last-of-type {
                      margin-bottom: 0;
                    }

                    > .desc {
                      .dy-inl-bl();
                    }

                    .time {
                      .dy-inl-bl();

                      span {
                        margin-right: 10px;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .alarm {
          .form {
            width: 100%;
          }
          .alarm-detail {
            .data-table {
              width: 100%;
              margin: 0;
            }
          }
        }

        .scenario {
          .form {
            .form {
              width: 100%;
              margin-bottom: 0;
            }
    
            .fieldset {
              .item {
                width: 200px;
                max-width: 70%;
              }
    
              .navigation {
                .step {
                  display: none;
                }
    
                .icon {
                  .font-size(32px, 0);
                }
              }
            }
          }

          .scenario-detail {            
            .box-image {
              position: fixed;
              background-color: @color-white;
              bottom: 0;
              right: 0;
              width: 50vw;
              height: 50vh;
              margin-left: 0;
              margin-top: 0;

              > .content{
                padding: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                .border-box();

                .image{
                  max-width: 100%;
                  max-height: 100%;
                }
              }
            }
          }
        }
      }

      > .overview {
        > .message {
          position: fixed;
          inset: auto 0 0 0;
          z-index: 101;
          padding: 20px;
          .color-rgba(@color-white, 0.85);
          background-color: @color-rgba;
          min-height: 0;
        }
      }

      &[data-type='scenario'] {
        > .pan {
          padding: 0 20px;
          left: 50vw;
          top: 0;
          right: auto;
          bottom: auto;
          width: 50vw;
          height: 100vh;
          padding-bottom: 50vh;
          .border-box();

          > .wrap-tripunit {
            height: 50vh;

            > .zoom {
              display: none;
            }
          }
        }

        .data-edit {
          z-index: 120;
          left: 0;
          overflow-y: auto;
          overflow-x: auto;
        }
      }
    }

    &.edit {
      .wrap-tap {
        visibility: visible;
        .opacity(1);
      }

      .data-edit {
        visibility: visible;
        .opacity(1);
        left: 0;
      }
    }
  }

  .data-table {
    .table {
      .tbody {
        .row {
          .cel {
            &[data-desc] {
              padding: 10px;
            }
          }
        }
      }
    }
  }
}
