@import 'Variables.less';
@import 'Standard/Mixins.less';

@media @media-rd-1200 {
  .wrap-tripunit {
    .tripunit .display .screen .setting-list > .content > .wrapper > .list-item {
      gap: 1px;

      > .title,
      > .button {
        height: 35px;
        .font-size(12px, 14px);
      }

      .value {
        height: 18.5px;
      }
    }
  }
}
