@import 'Variables.less';
@import 'Standard/Mixins.less';

@media @media-rd-smartphone {
  .main-title {
    padding-top: 8px;
    .font-size(22px, 26px);

    &:before {
      width: 28px;
      height: 3px;
    }
  }

  .app-container {
    padding-top: 0;

    > .sidebar {
      &[data-tripunit] {
        position: fixed;
        z-index: 11;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: @color-white;
        .color-rgba(@color-black, 0.35);
        .shadow( 0px 6px 12px @color-rgba);
      }

      > .content {
        .tripmenu {
          .back {
            display: none;
          }

          > .list-item {
            width: 100%;
            .font-size();

            .button {
              .aspect-ratio(64, 48);
              .dy-inl-bl();
              width: 20%;
              max-width: 100px;

              &:hover {
                background-color: @color-white;
              }

              > .content {
                color: @color-gray;

                .label {
                  font-size: 10px;
                }
              }

              &[data-selected] {
                background-color: @color-white;

                &:hover {
                  background-color: @color-white;
                }

                > .content {
                  color: @color-black;

                  &:before {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }

    > .content {
      padding: 0;
      min-height: 93vh;

      &[data-sidebar='false'] {
        padding-left: 0;
      }

      .main {
        background-color: @color-grayxxl;
        padding: 0 0 20px 0;

        > .header {
          background-color: @color-white;
          padding: 20px 20px 8px 20px;
          margin-bottom: 0;
          display: flex;
          gap: 0;
          flex-direction: column;
          position: relative;

          > .app-width {
            display: block;
            text-align: center;

            .main-title {
              padding-top: 5px;
              top: -3px;
              position: relative;
              .font-size(14px, normal);
              .dy-inl-bl();

              &:before {
                height: 2px;
              }
            }

            .item {
              display: none;
            }
          }

          .actions {
            position: fixed;
            z-index: 2;
            bottom: 71px;
            right: 15px;

            .cta {
              .button {
                padding-left: 0 !important;
                padding-right: 0 !important;
                text-align: center;
                width: 40px;
                background-color: @color-white;

                .icon {
                  margin-right: 0 !important;
                }

                .label {
                  display: none;
                }
              }
            }
          }

          .culture-selector {
            position: absolute;
            top: 17px;
            right: 20px;

            .button {
              justify-content: flex-end;

              .label {
                &.title {
                  display: none;
                }

                &.tag {
                  .dy-inl-bl();
                }
              }

              .xt-angle-bottom {
                display: none;
              }
            }
          }

          > .back {
            display: block;
            .font-size(24px, 0);
            position: absolute;
            top: 17px;
            left: 20px;
            color: @dft-font-color;
          }
        }
      }

      .app-footer {
        padding-top: 0;
      }
    }
  }

  .device-list,
  .data-table {
    background-color: @color-white;
    width: ~'calc(100% - 20px)';
    margin: 10px;
  }

  .device-list {
    > .item {
      border: 0;
      border-bottom: 1px solid @color-grayxxl;
      width: 100%;
      margin: 0 !important;

      &:last-of-type {
        border-bottom: 0;
      }

      .box-image,
      .info {
        .dy-inl-bl();
        vertical-align: middle;
      }

      .box-image {
        width: 35%;
      }

      .info {
        .title {
          margin: 0;
        }

        .desc {
          display: none;
        }
      }

      &:hover {
        border-color: @color-grayxxl;

        .info {
          .title {
            color: @dft-font-color;
          }
        }
      }
    }

    &[data-family] {
      > .item {
        border: 0;
        border-bottom: 1px solid @color-grayxxl;
        width: 100%;
        margin: 0 !important;
        display: inline-flex;
        flex-direction: column-reverse;

        .box-image {
          width: 100%;
        }

        .info {
          padding-bottom: 0;
        }
      }
    }
  }

  .data-table {
    &[data-library] {
      width: ~'calc(100% + 40px)';
      margin: 0 auto 0 -20px;
    }

    .table {
      &[data-type='tripunits'],
      &[data-type='library'] {
        .tbody {
          display: block;

          .row {
            border: 0;
            border-bottom: 1px solid @color-grayxxl;
            width: 100%;
            margin: 0 !important;

            &:last-of-type {
              border-bottom: 0;
            }
          }
        }
      }

      .tbody {
        .row {
          .cel {
            &[data-desc] {
              padding: 10px;
            }
          }
        }
      }
    }
  }

  .app-tripunit {
    background-color: @color-white;
    width: ~'calc(100vw - 20px)';
    margin: 10px;
    padding: 20px;
    .border-box();

    &:not([data-info='true']) {
      padding-bottom: 0;

      > .wrapper {
        > .pan {
          margin-bottom: 0;
        }
      }
    }

    > .wrapper {
      > .pan {
        position: relative;
        width: ~'calc(100% + 40px)';
        margin-left: -20px;
        overflow: hidden;

        > .wrap-tripunit {
          display: flex;
          justify-content: center;
          align-items: center;

          > .tripunit {
            left: auto;
            .transform (translateX(0));;
          }

          > .zoom {
            display: block;

            .icon {
              position: absolute;
              bottom: 10px;
              right: 10px;
              color: @dft-font-color;
              .font-size(24px, 0);
              z-index: 1;
            }

            .message {
              position: absolute;
              inset: 0;
              .color-rgba(@color-white, 0.3);
              background-color: @color-rgba;
              padding: 10px;
              text-align: center;
              display: flex;
              justify-content: center;
              align-items: center;
              .opacity(0);
              visibility: hidden;
              .transition(@dft-transition);

              .smartphone {
                .font-size(60px, 0);
                color: @color-white;
                .color-rgba(@color-black, 0.35);
                text-shadow: 0 0 5px @color-rgba;
              }

              &[data-show='true'] {
                .opacity(1);
                visibility: visible;

                .smartphone {
                  .animation(rotation 1.5s infinite linear);

                  @-webkit-keyframes rotation {
                    .rotationframes;
                  }

                  @-moz-keyframes rotation {
                    .rotationframes;
                  }

                  @-ms-keyframes rotation {
                    .rotationframes;
                  }

                  @-o-keyframes rotation {
                    .rotationframes;
                  }

                  @keyframes rotation {
                    .rotationframes;
                  }

                  .rotationframes() {
                    0% {
                      -webkit-transform: rotate(0deg);
                      transform: rotate(0deg);
                    }

                    15% {
                      -webkit-transform: rotate(0deg);
                      transform: rotate(0deg);
                    }

                    40% {
                      -webkit-transform: rotate(90deg);
                      transform: rotate(90deg);
                    }

                    60% {
                      -webkit-transform: rotate(90deg);
                      transform: rotate(90deg);
                    }

                    75% {
                      -webkit-transform: rotate(0deg);
                      transform: rotate(0deg);
                    }

                    100% {
                      -webkit-transform: rotate(0deg);
                      transform: rotate(0deg);
                    }
                  }
                }
              }
            }
          }
        }
      }

      > .overview {
        min-height: 15vh;
      }

      &[data-selector] {
        position: relative;
        padding-top: 88px;

        .alarm,
        .scenario {
          .form {
            border-top: 10px solid @color-grayxxl;
            background-color: @color-white;
            width: ~'calc(100vw - 20px)';
            position: absolute;
            top: 0;
            left: -20px;
            padding: 15px 20px 20px 20px;
            .border-box();
          }
        }
      }
    }

    .settings {
      padding: 20px 0;
      gap: 20px;

      .parameters {
        width: 100%;

        > .form {
          > .fieldset {
            flex-direction: column;
            gap: 20px;
          }
        }
      }

      > .tabel-settings {
        width: ~'calc(100% + 40px)';
        margin-left: -20px;
        margin-bottom: 20px;

        .data-table {
          width: 100%;
          margin: 0;

          .table {
            .thead {
              display: none;
            }

            .tbody {
              display: block;
            }

            .row {
              display: block;
              border-bottom: 1px solid @color-grayxxl;
              padding: 15px 20px 15px 50px;
              position: relative;

              .cel {
                display: block;
                border: 0;
                padding: 0;
                margin-bottom: 8px;

                &[data-type='version'] {
                  position: absolute;
                  left: 20px;
                  top: 15px;
                }

                &:last-of-type {
                  margin-bottom: 0;
                }

                > .desc {
                  .dy-inl-bl();
                }

                .time {
                  .dy-inl-bl();

                  span {
                    margin-right: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .alarm {
      .form {
        width: 100%;
      }

      .alarm-detail {
        .data-table {
          width: 100%;
          margin: 0;
        }
      }
    }

    .scenario {
      .form {
        .form {
          width: 100%;
          margin-bottom: 0;
        }

        .fieldset {
          .item {
            width: 200px;
            max-width: 70%;
          }

          .navigation {
            .step {
              display: none;
            }

            .icon {
              .font-size(32px, 0);
            }
          }
        }
      }

      .scenario-detail {
        display: flex;
        flex-direction: column;

        .description,
        .box-image {
          width: 100%;
          display: block;
        }

        .description {
          order: 2;
          margin-top: 20px;
        }

        .box-image {
          margin-top: 0;
          order: 1;
          .aspect-ratio(605, 341);

          > .content {
            display: flex;
            justify-content: center;
            align-items: center;
            .border-box();

            .image {
              max-width: 100%;
              max-height: 100%;
            }
          }
        }
      }
    }
  }

  .insight{
    margin-top: 20px;
  }
}
