//1.3.0
@import '../../Variables.less';
@import '../../Standard/Mixins.less';

input[type='number'] {
  -moz-appearance: textfield !important;
}

input[type='text'],
input[type='email'],
input[type='password'] {
  .border-radius();
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:required,
input:invalid {
  box-shadow: none;
}

input:-webkit-autofill,
select:-webkit-autofill,
textarea:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px @color-white inset;
  box-shadow: 0 0 0 1000px @color-white inset;
  -moz-transition: background-color 5000s ease-in-out 0s;
  -o-transition: background-color 5000s ease-in-out 0s;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

.field-validation-error {
  color: @color-valid;
}

.input-validation-error {
  border-color: @color-valid !important;
  background-color: lighten(@color-valid, 40%) !important;
  color: @color-valid;
}

.input-validation-error:focus {
  border-color: @color-valid !important;
}

.validation-summary-errors {
  color: @color-valid;
  padding-left: 25px;
  position: relative;

  &:before {
    content: '\e801';
    font-family: 'fontello';
    font-size: 14px;
    position: absolute;
    top: 0;
    left: 0;
  }

  > ul {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0;

    > li {
      list-style: none;
      .dy-inl-bl();

      &:after {
        content: ',';
        .dy-inl-bl();
        margin-right: 5px;
      }

      &:last-of-type {
        &:after {
          display: none;
        }
      }
    }
  }
}

.form {
  @input-border: @color-grayl;
  @input-focus: @color-blue;

  select::-ms-expand {
    display: none;
  }

  .fieldset {
    margin-top: 25px;

    &:first-of-type {
      margin-top: 0;
    }

    > .item {
      display: flex;
      align-items: center;
      margin-top: 15px;

      &:first-of-type {
        margin-top: 0;
      }

      &[data-required] {
        .label {
          &:after {
            content: '*';
            color: @color-valid;
            margin-left: 3px;
            .font-size(12px, normal);
          }
        }
      }

      > .label {
        width: 30%;
        color: @color-gray;

        &.bold{
          font-family: 'ABBvoice-Bold';
          color: @color-grayxd;
        }
      }

      > .control {
        width: 70%;
      }

      &[data-lable='top'] {
        flex-flow: column;
        align-items: baseline;

        > .label,
        > .control {
          width: 100%;
        }
      }
    }
  }

  .textbox,
  .textarea,
  .dropdown {
    width: 100%;
    font-family: @dft-font-family;
    .font-size(@dft-font-size, @dft-line-height);
    color: @color-grayxd;
    border: 1px solid @input-border;
    background-color: @color-white;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    padding: 6px 10px 3px 10px;
    .border-box();
    .placeholder-color(@color-gray);

    &:disabled {
      color: lighten(@color-gray, 10%);
      background-color: @color-grayxl;
      cursor: default;
      .opacity(1);
    }

    &:focus {
      border: 1px solid @input-focus;
    }
  }

  .textbox,
  .textarea {
    resize: none;
    .placeholder-color(lighten(@dft-font-color, 50%));
  }

  .textarea {
    margin-top: 5px;
    height: 100px;
    display: block;
  }

  .dropdown {
    cursor: pointer;
    background-image: url('@{path-images}dropdown.svg');
    .bg-size(32px, 32px);
    background-repeat: no-repeat;
    background-position: right;
    text-indent: 0.01px;
    .text-overflow(ellipsis);
  }

  .dropdown-label{
    position: relative;

    .label{
      position: absolute;
      top: 7px;
      right: 28px;
    }

    .dropdown{
      background-color: transparent;
      position: relative;
      z-index: 1;
    }
  }

  .checkbox {
    @size: 20px;

    .font-size(14px, normal);
    .disable-user-select();

    input[type='checkbox'] {
      display: none;
    }

    label {
      cursor: pointer;
      position: relative;
      .dy-inl-bl();

      &:before {
        content: '';
        position: relative;
        top: 4px;
        width: @size;
        height: @size;
        margin-right: 10px;
        border: 1px solid darken(@input-border, 5%);
        .dy-inl-bl();
        .border-box();
        .transition(@dft-transition);
      }

      &:after {
        font-family: 'fontello';
        content: '\e802';
        .font-size(12px, 16px);
        position: absolute;
        left: 4px;
        top: 5px;
        color: @color-white;
        .transition(@dft-transition);
      }
    }

    input[type='checkbox']:checked ~ label {
      &:before {
        background-color: @input-focus;
        border-color: @input-focus;
      }
    }

    .link {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .radiobutton {
    @size: 20px;
    @border: 1px;
    @difference: 10;

    cursor: pointer;
    margin: @border @size @border 0;
    .font-size(@dft-font-size, @dft-line-height);
    .disable-user-select();
    .border-box();
    .dy-inl-bl();

    label {
      cursor: pointer;
      position: relative;
      padding-left: @size + 10;
      .border-box();

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: -5px;
        width: @size;
        height: @size;
        border: @border solid darken(@input-border, 5%);
        background-color: @color-white;
        .dy-inl-bl();
        .border-radius(@size);
        .border-box();
        .transition(@dft-transition);
      }
    }

    input[type='radio'] {
      display: none;
      .border-box();

      & + label:after {
        content: '';
        display: block;
        position: absolute;
        left: 6px;
        top: 1px;
        width: 8px;
        height: 8px;
        background-color: @color-white;
        .border-radius(@size - @difference);
        .border-box();
      }

      &:checked + label:before {
        border-color: @input-focus;
        background-color: @input-focus;
      }
    }
  }

  .toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0;

    > .item {
      width: ~'calc(100% / 3)';
      text-align: center;
      border: 1px solid @color-grayl;
      background-color: @color-white;
      padding: 6px 10px 2px 10px;
      display: block;
      text-align: center;
      font-family: 'ABBvoice-Medium';
      cursor: pointer;

      &[data-selected]{
        background-color: @color-blue;
        border-color: @color-blue;
        color: @color-white;
      }

      &:first-of-type {
        .border-radius(@dft-radius 0 0 @dft-radius);
        border-right: 0;
      }

      &:last-of-type {
        .border-radius(0 @dft-radius @dft-radius 0);
        border-left: 0;
      }
    }
  }

  .policy-box {
    .font-size(@dft-font-size - 3, @dft-line-height - 3);
    color: @dft-font-color;
    border: 1px solid @input-border;
    margin: 10px 0;
    max-height: 65px;
    overflow-x: visible;
    overflow-y: auto;
    padding: 5px;

    a {
      color: @dft-font-color;
    }
  }
}
