//1.0.0
@import "../Variables.less";
@import "../Standard/Mixins.less";

/*Standard*/
.ClearBoth {
    clear: both;
    display: block;
    height: 0;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

#DivHeader {
    font-family: Arial !important;
    font-size: 12px !important;
    line-height: normal !important;

    strong {
        font-family: Arial !important;
    }
}

.cf {
    zoom: 1;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }
}

.fl-l {
    float: left;
}

.fl-r {
    float: right;
}

.cur-p {
    cursor: pointer;
}

.cur-d {
    cursor: default;
}

.dy-inl-bl {
    display: -moz-inline-stack;
    display: inline-block;
    zoom: 1;
    *display: inline;
}

.dy-tbl {
    display: table;
    -dt-display: table;
}

.dy-tbl-cell {
    display: table-cell;
    -dt-display: table-cell;
}

.hden {
    display: none;
}

.no-uline,
.no-uline:hover {
    text-decoration: none;
}

.uline,
.uline:hover {
    text-decoration: underline;
}

.txt-align-c {
    text-align: center;
}

.txt-align-r {
    text-align: right;
}

.txt-align-l {
    text-align: left;
}

.upcase {
    text-transform: uppercase;
}

.lwcase {
    text-transform: lowercase;
}

.lh-0 {
    line-height: 0;
}

.ovflw-hden {
    overflow: hidden;
}

img {
    width: auto;
    height: auto;
    max-width: 100%;
}

@media @media-retina {
    img.retina {
        width: auto;
        height: auto;
        max-width: 50%;
    }
}

/*Grid 12 columns*/
.span-1 {
    float: left;
    width: 8.33333333333%;
}

.span-2 {
    float: left;
    width: 16.6666666667%;
}

.span-3 {
    float: left;
    width: 25.0%;
}

.span-4 {
    float: left;
    width: 33.3333333333%;
}

.span-5 {
    float: left;
    width: 41.6666666667%;
}

.span-6 {
    float: left;
    width: 50.0%;
}

.span-7 {
    float: left;
    width: 58.3333333333%;
}

.span-8 {
    float: left;
    width: 66.6666666667%;
}

.span-9 {
    float: left;
    width: 75.0%;
}

.span-10 {
    float: left;
    width: 83.3333333333%;
}

.span-11 {
    float: left;
    width: 91.6666666667%;
}

.span-12 {
    width: 100%;
}

.offset-1 {
    margin-left: 8.33333333333%;
}

.offset-2 {
    margin-left: 16.6666666667%;
}

.offset-3 {
    margin-left: 25.0%;
}

.offset-4 {
    margin-left: 33.3333333333%;
}

.offset-5 {
    margin-left: 41.6666666667%;
}

.offset-6 {
    margin-left: 50.0%;
}

.offset-7 {
    margin-left: 58.3333333333%;
}

.offset-8 {
    margin-left: 66.6666666667%;
}

.offset-9 {
    margin-left: 75.0%;
}

.offset-10 {
    margin-left: 83.3333333333%;
}

.offset-11 {
    margin-left: 91.6666666667%;
}
