@import 'Variables.less';
@import 'Standard/Mixins.less';

@media @media-rd-1000 {
  .app-container {
    > .content {
      .main {
        > .header {
          display: grid;
          grid-template-columns: repeat(12, 1fr);
          grid-auto-rows: auto;
          grid-gap: 15px;

          > .app-width {
            grid-column: span 9;
            grid-row: span 1;
          }

          .actions {
            grid-column: span 12;
            grid-row: span 1;
            margin-left: 0;
          }

          .culture-selector {
            grid-column: 10 / 13;
            grid-row: 1;
            text-align: right;
            justify-content: right;
            display: flex;
          }
        }
      }
    }
  }
}
