//1.4.0
@import "../variables.less";

.font-face(@fontname, @fontfile) {
    @font-face {
        font-family: @fontname;
        src: url('@{path-fonts}@{fontfile}.woff2');
        src: local('@{fontfile}'), url('@{path-fonts}@{fontfile}.woff2') format('woff2'), url('http://:/') format("No-IE-404");
        font-weight: normal;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        font-display: swap;
    }
}

.font-size(@font: 0, @line: @font) {
    font-size: @font;
    line-height: @line;
}

.aspect-ratio(@proportion-width: 16, @proportion-height: 9) {
    position: relative;

    &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: (@proportion-height / @proportion-width) * 100%;
    }

    > .content {
        position: absolute;
        inset: 0;
    }
}

.bg-image(@image, @width, @height, @repeat: no-repeat) {
    @filename: ~ `/(.*)\.(jpg|jpeg|png|gif)/.exec(@{image})[1]`;
    @extension: ~ `/(.*)\.(jpg|jpeg|png|gif)/.exec(@{image})[2]`;

    background-image: ~'url(@{path-image}@{filename}.@{extension})';
    background-repeat: @repeat;
    background-size: @width @height;

    @media @media-retina {
        background-image: ~'url(@{path-image}@{filename}@2x.@{extension})';
    }
}

.bg-size(@width, @height) {
    -moz-background-size: @width @height;
    -o-background-size: @width @height;
    -webkit-background-size: @width @height;
    background-size: @width @height;
}

.bg-gradient (@startColor: #eee, @endColor: white) {
    background-color: @startColor;
    background: -webkit-gradient(linear, left top, left bottom, from(@startColor), to(@endColor));
    background: -webkit-linear-gradient(top, @startColor, @endColor);
    background: -moz-linear-gradient(top, @startColor, @endColor);
    background: -ms-linear-gradient(top, @startColor, @endColor);
    background: -o-linear-gradient(top, @startColor, @endColor);
    background: linear-gradient(left, @startColor,@endColor);
}

.bg-gradient-hor (@startColor: #eee, @endColor: white) {
    background-color: @startColor;
    background-image: -webkit-gradient(linear, left top, right top, from(@startColor), to(@endColor));
    background-image: -webkit-linear-gradient(left, @startColor, @endColor);
    background-image: -moz-linear-gradient(left, @startColor, @endColor);
    background-image: -ms-linear-gradient(left, @startColor, @endColor);
    background-image: -o-linear-gradient(left, @startColor, @endColor);
    background: linear-gradient(left, @startColor,@endColor);
}

.bg-gradient-rad (@startColor: #eee, @endColor: white) {
    background: @startColor;
    background: -webkit-gradient(radial, center center, center center, from(@startColor), to(@endColor));
    background: -webkit-radial-gradient(center, ellipse cover, @startColor, @endColor);
    background: -moz-radial-gradient(center, ellipse cover, @startColor, @endColor);
    background: -ms-radial-gradient(center, ellipse cover, @startColor, @endColor);
    background: -o-radial-gradient(center, ellipse cover, @startColor, @endColor);
    background: radial-gradient(ellipse at center, @startColor,@endColor);
}

.border-radius(@radius: 0) {
    -moz-border-radius: @radius;
    -webkit-border-radius: @radius;
    border-radius: @radius;
}

.border-box() {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.disable-user-select() {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.text-size-adjust() {
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    text-size-adjust: 100%;
}

.text-overflow(@value) {
    -ms-text-overflow: @value;
    -o-text-overflow: @value;
    text-overflow: @value;
}

.shadow(@shadow) {
    -moz-box-shadow: @shadow;
    -webkit-box-shadow: @shadow;
    box-shadow: @shadow;
}

.opacity(@percent) {
    -moz-opacity: @percent;
    opacity: @percent;
}

.blur(@pixel) {
    -webkit-filter: blur(@pixel);
    -moz-filter: blur(@pixel);
    -ms-filter: blur(@pixel);
    filter: blur(@pixel);
}

.color-rgba (@color, @opacity) {
    @color-rgba: rgba(red(@color), green(@color), blue(@color), @opacity);
}

.colWidth (@width, @cols, @margin, @margintop: @margin) {
    @marginTotal: (@cols - 1) * @margin;
    @tolerance: 0.01px;
    @itemWidth: ~"calc((@{width} - @{marginTotal} - @{tolerance}) / @{cols})";

    width: @itemWidth;
    margin: @margintop 0 0 @margin;

    &:nth-of-type(-n+@{cols}) {
        margin-top: 0;
    }

    &:first-of-type,
    &:nth-of-type(@{cols}n+1) {
        margin-left: 0;
    }
}

.colWidth (@width, @cols, @margin, @margintop: @margin) when (@margin = 0) {
    @marginTotal: (@cols - 1) * @margin;
    @tolerance: 0.01px;
    @itemWidth: ~"calc((@{width} - @{tolerance}) / @{cols})";

    width: @itemWidth;
    margin: @margintop 0 0 @margin;

    &:nth-of-type(-n+@{cols}) {
        margin-top: 0;
    }

    &:first-of-type,
    &:nth-of-type(@{cols}n+1) {
        margin-left: 0;
    }
}

.colWidthReset (@cols, @margin, @margintop: @margin) {
    &:nth-of-type(-n+@{cols}) {
        margin-top: @margintop;
    }

    &:first-of-type,
    &:nth-of-type(@{cols}n+1) {
        margin-left: @margin;
    }
}

.placeholder-color(@color) {
    &::-webkit-input-placeholder {
        color: @color;
    }

    &:-moz-placeholder {
        color: @color;
        opacity: 1;
    }

    &::-moz-placeholder {
        color: @color;
        opacity: 1;
    }

    &:-ms-input-placeholder {
        color: @color;
    }
}

.dy-inl-bl() {
    display: -moz-inline-stack;
    display: inline-block;
    zoom: 1;
    *display: inline;
}

.valign-middle() {
    position: absolute;
    top: 50%;
    .transform (translateY(-50%))
}

.text-column(@column, @gap) {
    -webkit-column-count: @column;
    -moz-column-count: @column;
    column-count: @column;
    -webkit-column-gap: @gap;
    -moz-column-gap: @gap;
    column-gap: @gap;
}

.breaking-long-words() {
    -ms-word-wrap: break-word;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
}

.triangle(@_, @width, @height, @color) {
    width: 0px;
    height: 0px;
    border-color: transparent;
    border-style: solid;
}

.triangle(top, @width, @height, @color) {
    border-width: 0 @width/2 @height @width/2;
    border-bottom-color: @color;
    border-left-color: fade(@color, 0);
    border-right-color: fade(@color, 0);
}

.triangle(bottom, @width, @height, @color) {
    border-width: @height @width/2 0 @width/2;
    border-top-color: @color;
    border-left-color: fade(@color, 0);
    border-right-color: fade(@color, 0);
}

.triangle(right, @width, @height, @color) {
    border-width: @height/2 0 @height/2 @width;
    border-left-color: @color;
    border-top-color: fade(@color, 0);
    border-bottom-color: fade(@color, 0);
}

.triangle(left, @width, @height, @color) {
    border-width: @height/2 @width @height/2 0;
    border-right-color: @color;
    border-top-color: fade(@color, 0);
    border-bottom-color: fade(@color, 0);
}

.triangle(top-right, @width, @height, @color) {
    border-width: 0 @width @height 0;
    border-right-color: @color;
    border-bottom-color: fade(@color, 0);
}

.triangle(top-left, @width, @height, @color) {
    border-width: @height @width 0 0;
    border-top-color: @color;
    border-right-color: fade(@color, 0);
}

.triangle(bottom-right, @width, @height, @color) {
    border-width: 0 0 @height @width;
    border-bottom-color: @color;
    border-left-color: fade(@color, 0);
}

.triangle(bottom-left, @width, @height, @color) {
    border-width: @height 0 0 @width;
    border-left-color: @color;
    border-top-color: fade(@color, 0);
}

.flex-flow() {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
}

/*Animation*/
.transform-3d () {
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.transform (@transform) {
    -moz-transform: @transform;
    -ms-transform: @transform;
    -o-transform: @transform;
    -webkit-transform: @transform;
    transform: @transform;
}

.transition (@transition) {
    -moz-transition: @transition;
    -o-transition: @transition;
    -webkit-transition: @transition;
    transition: @transition;
}

.animation (@anim) {
    -moz-animation: @anim;
    -o-animation: @anim;
    -webkit-animation: @anim;
    animation: @anim;
}

.animation-name (@name) {
    -moz-animation-name: @name;
    -o-animation-name: @name;
    -webkit-animation-name: @name;
    animation-name: @name;
}

.animation-duration(@duration) {
    -moz-animation-duration: @duration;
    -o-animation-duration: @duration;
    -webkit-animation-duration: @duration;
    animation-duration: @duration;
}

.animation-fill-mode(@mode) {
    -o-animation-fill-mode: @mode;
    -webkit-animation-fill-mode: @mode;
    -moz-animation-fill-mode: @mode;
    animation-fill-mode: @mode;
}

.animation-iteration-count(@mode) {
    -moz-animation-iteration-count: @mode;
    -o-animation-iteration-count: @mode;
    -webkit-animation-iteration-count: @mode;
    animation-iteration-count: @mode;
}

.animation-delay(@mode) {
    -moz-animation-delay: @mode;
    -o-animation-delay: @mode;
    -webkit-animation-delay: @mode;
    animation-delay: @mode;
}

.animation-timing-function(@mode) {
    -moz-animation-timing-function: @mode;
    -o-animation-timing-function: @mode;
    -webkit-animation-timing-function: @mode;
    animation-timing-function: @mode;
}

.animation-transform-origin(@x, @y) {
    -moz-transform-origin: @x @y;
    -webkit-transform-origin: @x @y;
    -o-transform-origin: @x @y;
    -ms-transform-origin: @x @y;
    transform-origin: @x @y;
}
