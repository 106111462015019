@import 'Variables.less';
@import 'Standard/Mixins.less';

@media @media-rd-1920 {
  .device-list {
    > .item {
      .colWidthReset(5, 30px);
      .colWidth(100%, 4, 30px);
    }
  }
}
