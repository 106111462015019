@import 'Variables.less';
@import 'Standard/Mixins.less';

.font-face('ABBvoice', ABBvoice);
.font-face('ABBvoice-Light', ABBvoice-Light);
.font-face('ABBvoice-Medium', ABBvoice-Medium);
.font-face('ABBvoice-Bold', ABBvoice-Bold);

/*Application*/
body {
  font-family: @dft-font-family;
  .font-size(@dft-font-size, @dft-line-height);
  color: @dft-font-color;
  background-color: @color-grayxxl;
  -webkit-tap-highlight-color: @color-rgba;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
  .text-size-adjust();
}

*[data-preload] {
  -moz-transition: none !important;
  -o-transition: none !important;
  -webkit-transition: none !important;
  transition: none !important;
}

.mrg {
  &[data-mrgb='0'] {
    margin-bottom: 0px !important;
  }

  &[data-mrgb='10'] {
    margin-bottom: 10px !important;
  }

  &[data-mrgb='20'] {
    margin-bottom: 20px !important;
  }
}

.loader-box {
  @loader-size: 32px;

  padding: 20px @loader-size @loader-size @loader-size;
  text-align: center;

  .loader {
    margin: auto;
    border: 3px solid @color-blue;
    border-top-color: transparent;
    height: @loader-size;
    width: @loader-size;
    padding: 0;
    .border-radius(100%);
    .animation(loading 0.7s infinite linear);
    .border-box();

    @-webkit-keyframes loading {
      .loadingframes;
    }

    @-moz-keyframes loading {
      .loadingframes;
    }

    @-ms-keyframes loading {
      .loadingframes;
    }

    @-o-keyframes loading {
      .loadingframes;
    }

    @keyframes loading {
      .loadingframes;
    }

    .loadingframes() {
      from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }

      to {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
      }
    }
  }
}

.app-width {
  width: 100%;
  max-width: @app-maxwidth;
  .border-box();
}

.main-title {
  padding-top: 15px;
  position: relative;
  font-family: 'ABBvoice-Bold';
  .font-size(32px, 40px);

  &:before {
    content: '';
    position: absolute;
    inset: 0 auto auto 0;
    width: 40px;
    height: 4px;
    background-color: @color-red;
  }
}

.cta {
  a,
  .button {
    font-family: @dft-font-family;
    .font-size(@dft-font-size, normal);
    cursor: pointer;
    text-decoration: none;
    color: @color-grayxd;
    background-color: transparent;
    border: 2px solid @color-grayxd;
    padding: 11px 20px 7px 20px !important;
    outline: none;
    -webkit-appearance: none;
    .disable-user-select();
    .transition(@dft-transition);
    .border-radius(20px);
    .border-box();
    .dy-inl-bl();

    &:hover {
      background-color: @color-grayxxl;
    }

    &[data-icon] {
      padding-left: 13px !important;

      .icon {
        position: relative;
        top: 1px;
        margin-right: 8px;
        line-height: 0;
      }
    }

    &[data-border='0'] {
      border-color: transparent;

      &:hover {
        border-color: @color-grayxd;
        background-color: transparent;
      }
    }

    &[data-pdg='0'] {
      background-color: transparent;
      padding: 0 !important;

      &:hover {
        background-color: transparent;
        border-color: transparent;
        color: @color-grayxd;
        text-decoration: underline;
      }
    }

    &[data-color='blue'] {
      color: @color-blue;

      &:hover {
        color: @color-blue;
      }
    }

    &[data-rounded] {
      .border-radius(4px);
      border: 0;
      color: @dft-font-color;

      &:hover {
        background-color: @color-grayxl;
      }
    }

    &[data-bg='blue'] {
      background-color: @color-blue;
      color: @color-white;

      &:hover {
        background-color: @color-bluel;
      }
    }
  }
}

[data-buttons] {
  .cta {
    .dy-inl-bl();

    &:not(:first-child) {
      margin-left: 5px !important;
    }
  }
}

.app-header {
  background-color: @color-white;
  padding: 10px;
  position: relative;

  .logo {
    display: block;
  }

  .name {
    .font-size(12px, normal);
    font-weight: bold;
    position: absolute;
    left: 50%;
    top: 50%;
    .transform (translate(-50%, -50%));;
  }
}

.culture-selector {
  position: relative;

  .button {
    display: flex;
    align-items: center;
    gap: 6px;
    color: @color-blue;
    cursor: pointer;

    .label {
      position: relative;
      top: 1px;
      .font-size(14px, normal);
    }

    .icon {
      .font-size(19px, 0);

      &.small {
        .font-size(12px, 0);
      }
    }
  }

  .exp {
    position: absolute;
    min-width: 160px;
    z-index: 1;
    top: 25px;
    right: 0;
    background-color: @color-white;
    padding: 8px 0;
    .color-rgba(@color-black, 0.35);
    .shadow(0px 6px 12px @color-rgba);
    visibility: hidden;
    .opacity(0);
    .transition(@dft-transition);

    &.show {
      visibility: visible;
      .opacity(1);
    }

    > .item {
      padding: 8px 13px 8px 16px;
      .font-size(14px, 16px);
      cursor: pointer;

      &.disabled {
        cursor: default;
        color: @color-grayl;
      }
    }
  }
}

.app-container {
  padding-top: 10px;
  padding-bottom: 15px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  min-height: ~'calc(100vh - 35px)';
  .border-box();

  > .sidebar {
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;

    > .content {
      position: sticky;
      top: 0;

      > .show-search {
        display: none;
      }

      .search {
        width: 280px;
        padding: 15px 10px 0 10px;
        .border-box();
      }

      .tripmenu {
        .back {
          padding: 0 10px 10px 10px;
          cursor: pointer;

          .icon {
            .font-size(24px, 0);
          }
        }

        > .list-item {
          width: 120px;
          text-align: center;

          .button {
            cursor: pointer;
            .aspect-ratio(1, 1);
            .border-box();
            .transition(@dft-transition);

            &:hover {
              background-color: @color-grayxl;
            }

            > .content {
              display: flex;
              flex-flow: column;
              justify-content: center;
              gap: 6px;

              .icon {
                .font-size(24px, 0);
              }

              .label {
                font-family: 'ABBvoice-Medium';
              }
            }

            &[data-selected] {
              background-color: @color-grayxl;

              > .content {
                &:before {
                  content: '';
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  width: 4px;
                  background-color: @color-blue;
                }
              }
            }
          }
        }
      }
    }
  }

  > .content {
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-right: 10px;

    &[data-sidebar='false'] {
      padding-left: 10px;
    }

    .main {
      background-color: @color-white;
      -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      padding: 30px;

      > .header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        align-items: center;
        gap: 20px;

        > .app-width {
          display: flex;
          gap: 20px;

          .box-image {
            width: 140px;
            .aspect-ratio(368, 278);

            > .content {
              border: 1px solid @color-grayxl;
              background-color: @color-grayxxl;
              .border-box();
            }
          }
        }

        .actions {
          margin-left: auto;
          white-space: nowrap;
        }

        > .back {
          display: none;
        }
      }
    }

    .app-footer {
      -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
      flex: 0 1 auto;
      padding-top: 15px;

      > .wrap-tap {
        display: none;
      }

      > .content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .font-size(12px, normal);

        > .close {
          display: none;
        }

        > .list-item {
          display: flex;
          align-items: center;
          gap: 20px;

          > .item {
            .link {
              color: @color-gray;
              .transition(@dft-transition);

              &:hover {
                color: @dft-font-color;
              }
            }
          }
        }

        > .copy {
          font-family: 'ABBvoice-Medium';
          padding-right: 20px;
        }
      }
    }
  }
}

.device-list,
.data-table {
  width: 100%;
  max-width: 2300px;
}

.device-list {
  .flex-flow();

  > .item {
    cursor: pointer;
    text-decoration: none;
    color: @dft-font-color;
    border: 1px solid @color-grayxl;
    .border-box();
    .colWidth(100%, 5, 30px);
    .dy-inl-bl();
    .transition(@dft-transition);

    .box-image {
      .aspect-ratio(368, 278);

      > .content {
        background-color: @color-grayxxl;

        .image {
          width: 100%;
        }
      }
    }

    .info {
      padding: 20px;

      .title {
        font-family: 'ABBvoice-Bold';
        .font-size(16px, normal);
        margin-bottom: 10px;
        .transition(@dft-transition);
      }
    }

    &:hover {
      border-color: @color-blue;

      .info {
        .title {
          color: @color-blue;
        }
      }
    }
  }

  &[data-family] {
    > .item {
      .colWidthReset(5, 30px);
      .colWidth(100%, 3, 30px);
    }
  }
}

.data-table {
  .table {
    text-align: left;
    width: 100%;

    .thead,
    .tbody {
      .row {
        .cel {
          border-bottom: 1px solid @color-grayl;
          padding: 13px 16px 9px 16px;
          min-width: 150px;
          .border-box();

          &[data-width='auto'] {
            min-width: 0;
          }

          &[data-border='rt'] {
            border-right: 1px solid @color-grayl !important;
          }

          &[data-desc] {
            padding: 20px 50px;
            width: 700px;
            vertical-align: middle;
            border-color: @color-grayxd;
          }

          &.form {
            > div {
              position: relative;
              top: -2px;
            }
          }
        }
      }
    }

    .thead {
      .row {
        .cel {
          font-family: 'ABBvoice-Bold';
          border-color: @color-grayxd;
          text-transform: uppercase;
          white-space: nowrap;
          .font-size(12px, normal);
          font-weight: normal;

          .lable {
            color: @color-gray;
            .dy-inl-bl();

            .icon {
              color: @dft-font-color;
              display: none;
              margin-left: 15px;
              .font-size(14px, 0);
            }

            &[data-dark] {
              color: @dft-font-color;
            }
          }

          &[data-order] {
            border-bottom: 2px solid @color-blue;

            .lable {
              .icon {
                .dy-inl-bl();
              }
            }
          }
        }

        &[data-compact] {
          .cel {
            padding-bottom: 0;
            text-transform: none;
            .font-size(@dft-font-size, @dft-line-height);
          }
        }
      }
    }

    .tbody {
      .row {
        cursor: pointer;
        .transition(@dft-transition);

        &[data-hover='false'] {
          cursor: default;
        }

        .cel {
          vertical-align: middle;

          &[data-type='version'] {
            font-weight: bold;
          }

          &[data-type='short'] {
            width: 300px;
          }

          &[data-align='rt'] {
            text-align: right;
          }

          &[data-valign='top'] {
            vertical-align: top;
          }

          .time {
            display: flex;
            justify-content: space-between;
          }
        }

        &[data-alarmdescription] {
          .cel {
            &.placeholder {
              padding: 0;
              border-bottom: none;
            }
          }
        }

        &:hover {
          background-color: @color-grayxxl;

          &[data-hover='false'] {
            background-color: transparent;
          }
        }

        &:last-of-type {
          .cel {
            border-color: @color-grayxd;
          }
        }

        &[data-disabled] {
          background-color: @color-grayxxl;
          color: lighten(@color-gray, 10%);

          &:hover {
            background-color: @color-grayxxl;
          }
        }

        .tooltip {
          position: relative;

          .text {
            background-color: @color-grayxd;
            color: @color-white;
            white-space: nowrap;
            padding: 7px 10px 4px 10px;
            visibility: hidden;
            .opacity(0);
            .transition(@dft-transition);
            position: absolute;
            bottom: 25px;
            left: 50%;
            .transform (translateX(-50%));;
            z-index: 1;
          }

          &:hover {
            .text {
              visibility: visible;
              .opacity(1);
            }
          }
        }
      }
    }

    &[data-thead='false'] {
      .tbody {
        .row {
          &:first-of-type {
            .cel {
              border-top: 1px solid @color-grayxd;
            }
          }
        }
      }
    }
  }
}

.app-tripunit {
  > .wrapper {
    > .pan {
      margin-bottom: 15px;
    }

    > .overview {
      min-height: 100px;

      .title {
        font-family: 'ABBvoice-Bold';
        .font-size(16px, normal);
        margin-bottom: 10px;
        .transition(@dft-transition);
      }
    }
  }

  .settings {
    margin-bottom: 30px;
    display: flex;
    gap: 40px;

    > .parameters {
      width: 190px;
    }

    > .tabel-settings {
      margin-top: -13px;
      width: 580px;

      .warning {
        margin-top: 15px;
        color: @color-orange;
      }
    }

    > .chart {
      margin-top: 19px;
      margin-left: auto;

      > .content {
        position: relative;
        padding-left: 30px;

        .axis {
          .label {
            font-family: 'ABBvoice-Bold';
            color: @dft-font-color;
            display: block;
            white-space: nowrap;
            .font-size(14px, normal);
          }

          &.y {
            position: absolute;
            width: 30px;
            left: 0;
            top: 0;
            bottom: 0;

            .label {
              text-orientation: mixed;
              writing-mode: vertical-rl;
              .transform(rotate(180deg));
            }
          }

          &.x {
            .box-image {
              .image {
                display: block;
              }
            }

            .label {
              margin-top: 8px;
            }
          }
        }
      }
    }
  }

  .alarm {
    display: flex;
    flex-flow: column;
    gap: 25px;

    .form {
      width: 300px;
    }

    .alarm-detail {
      width: 100%;
      max-width: 820px;

      .description {
        .title {
          font-family: 'ABBvoice-Bold';
          .font-size(16px, normal);
          margin-bottom: 10px;
          .transition(@dft-transition);
        }
      }
    }
  }

  .scenario {
    .form {
      width: 50%;
      margin-bottom: 25px;

      .fieldset {
        display: flex;
        align-items: end;
        justify-content: space-between;

        .item {
          width: 300px;
        }

        .navigation {
          display: flex;
          align-items: center;
          gap: 10px;

          .step {
            font-family: 'ABBvoice-Bold';
            .font-size(14px, normal);
          }

          .icon {
            .font-size(40px, 0);
            color: @dft-font-color;
            cursor: pointer;

            &[data-disabled='true'] {
              color: @color-grayxxl;
            }
          }
        }
      }
    }

    .scenario-detail {
      .font-size();

      .description,
      .box-image {
        width: 50%;
        vertical-align: top;
        .font-size(@dft-font-size, @dft-line-height);
        .dy-inl-bl();
        .border-box();
      }

      .description {
        padding: 30px;
        border-top: 1px solid @color-grayxd;
        border-bottom: 1px solid @color-grayxd;

        .title {
          font-family: 'ABBvoice-Bold';
          .font-size(16px, normal);
          margin-bottom: 10px;
          .transition(@dft-transition);
        }
      }

      .box-image {
        position: relative;
        top: -78px;
        padding-left: 15px;

        .image {
          position: relative;
          z-index: 1;
        }

        .loader-box {
          position: absolute;
          left: 50%;
          top: 50%;
          .transform (translate(-50%, -50%));;
        }
      }
    }
  }

  .print-note {
    display: none;
  }
}

.wrap-tripunit {
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: @color-tripunit;

  &.loading {
    width: 1280px;
    visibility: hidden;
    position: absolute;
    top: -100%;
    left: -100% !important;
  }

  .tripunit {
    position: relative;

    > .background {
      width: auto;
      display: block;
    }

    .sensitive-area {
      cursor: pointer;
      position: absolute;

      &.blink {
        animation-name: animblink;
        animation-duration: 500ms;
        animation-iteration-count: 2;
        animation-direction: alternate;
        animation-timing-function: ease-in;

        @keyframes animblink {
          0% {
            .color-rgba(@color-bluexl, 0);
            background-color: @color-rgba;
            border-color: @color-blue;
          }

          100% {
            .color-rgba(@color-bluexl, 0.3);
            background-color: @color-rgba;
            border-color: @color-blue;
          }
        }
      }

      &[data-selected] {
        .color-rgba(@color-bluexl, 0.5);
        background-color: @color-rgba;
        border-color: @color-blue;
      }
    }

    .switches {
      position: absolute;
      display: flex;
      .border-box();
      .transition(@dft-transition);
      .font-size();

      &[data-type='Rem/Loc'],
      &[data-type='Man/Elt'] {
        > .info {
          top: 50%;
          left: 100%;
          .transform (translateX(0%) translateY(-55%));;
          padding-left: 10px;
        }
      }

      > .item {
        cursor: pointer;
        .transition(@dft-transition);

        > .dipswitch {
          display: flex;

          img {
            width: 100% !important;
            display: block;
          }
        }
      }

      > .info {
        top: 100%;
        left: 50%;
        .transform (translateX(-50%));;
        position: absolute;

        > .name {
          color: @color-white;
          .font-size(12px, normal);
        }

        > .textbox {
          text-align: center;
          margin-top: 10px;

          .label {
            display: block;
            color: @color-white;
            .font-size(22px, normal);
            margin-right: 5px;
          }

          .input {
            text-align: center;
            display: block;
            width: 45px;
            padding: 6px 5px 4px 5px;
            border: 1px solid @color-black;
            .font-size(16px, normal);
            .border-box();
          }
        }
      }
    }

    .displayButton {
      cursor: pointer;
      position: absolute;
    }

    .display {
      position: absolute;
      display: flex;
      .border-box();
      .transition(@dft-transition);
      .font-size();

      .screen {
        position: absolute;
        inset: 0;
        z-index: 1;

        .item {
          position: absolute;
          inset: 0;

          .widget {
            position: absolute;
            inset: 0;
            cursor: pointer;
          }

          .selected {
            background-color: rgba(0, 0, 255, 0.5);
          }

          &[data-touch='false'] {
            .selected {
              border: 2px solid @color-black !important;
              background-color: transparent;
            }
          }
        }

        .setting-list {
          @navigation-size: 50px;

          .font-size(@dft-font-size, @dft-line-height);
          background-color: @color-black;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: space-between;
          color: @color-white;

          .navigation {
            width: @navigation-size;
            height: @navigation-size;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            .icon {
              color: @color-white;
              .font-size(32px, 0);
            }
          }

          > .content {
            width: 100%;
            display: flex;
            flex-direction: column;

            > .top {
              display: flex;
              align-items: center;
              height: @navigation-size;
              background-color: @color-black;

              .title {
                width: ~'calc(100% - (@{navigation-size} * 2))';
                text-align: center;
                .font-size(16px, normal);
                font-family: 'ABBvoice-Bold';
                color: @color-white;
              }

              &[data-navigation='false'] {
                .title {
                  width: 100%;
                }
              }
            }

            > .wrapper {
              height: ~'calc(100% - @{navigation-size})';
              background-color: @color-white;

              &[data-valign='vertical'] {
                display: flex;
                justify-content: center;
                align-items: center;
              }

              > .list-item {
                display: flex;
                color: @dft-font-color;
                flex-direction: column;
                gap: 3px;

                > .title,
                > .button {
                  height: 44.5px;
                  padding: 0 10px;
                  display: flex;
                  align-items: center;
                }

                .selected {
                  background-color: rgba(0, 0, 255, 0.5);
                }

                > .title {
                  font-family: 'ABBvoice-Bold';
                  .font-size(16px, normal);
                  color: @color-blue;
                }

                > .button {
                  padding: 0 10px;
                  line-height: 16px;
                  background-color: @color-grayxxl;
                  cursor: pointer;

                  .info {
                    .title {
                      color: @color-blue;

                      &.black {
                        color: @dft-font-color;
                      }
                    }
                  }

                  .check {
                    margin-left: auto;

                    .icon {
                      color: @color-blue;
                      .font-size(22px, 0);
                    }
                  }

                  &[data-selected] {
                    background-color: @color-grayxl;

                    .info {
                      .title {
                        &.black {
                          color: @color-blue;
                        }
                      }
                    }
                  }
                }

                &[data-column='true'] {
                  padding: 5px 0;
                  .flex-flow();
                  gap: 0;

                  .value {
                    .colWidth(100%, 2, 0);
                    .dy-inl-bl();
                  }
                }

                .value {
                  padding: 0 10px;
                  display: flex;
                  gap: 8px;
                  align-items: center;
                  color: @color-blue;
                  height: 23px;
                  .border-box();

                  &:first-of-type {
                    margin-top: 5px;
                  }

                  .icon {
                    width: 15px;

                    .image {
                      display: block;
                    }
                  }

                  .name {
                    width: 35%;
                  }

                  .data {
                    color: @dft-font-color;
                  }
                }

                &[data-type='list-selector'] {
                  background-color: @color-grayxl;

                  > .title {
                    background-color: @color-white;
                  }

                  > .button {
                    background-color: @color-white;

                    &[data-selected] {
                      background-color: @color-grayxl;
                    }
                  }
                }
              }

              > .numeric-selector {
                .font-size(@dft-font-size, @dft-line-height);
                color: @dft-font-color;
                width: 100%;
                padding: 30px;
                .border-box();

                > .title {
                  font-family: 'ABBvoice-Bold';
                  .font-size(16px, normal);
                  color: @color-blue;
                  text-align: center;
                  margin-bottom: 15px;
                }

                > .selected-value {
                  text-align: center;
                  .font-size(20px, normal);
                  margin-bottom: 5px;

                  .coefficient {
                    .font-size(16px, normal);
                  }
                }

                .app-range {
                  @button-width: 50px;

                  display: flex;
                  justify-content: space-between;
                  margin-bottom: 15px;

                  > .button {
                    text-transform: uppercase;
                    width: @button-width;
                    cursor: pointer;

                    &[data-max] {
                      text-align: right;
                    }
                  }

                  > .box-range {
                    width: ~'calc(100% - (@{button-width} * 2))';

                    .range {
                      width: 100%;
                      height: 15px;
                      -webkit-appearance: none;
                      width: 100%;

                      &:focus {
                        outline: none;
                      }

                      &::-webkit-slider-runnable-track {
                        width: 100%;
                        height: 5px;
                        background: @color-grayl;
                      }

                      &::-webkit-slider-thumb {
                        // box-shadow: 1px 1px 1px #000000;
                        // border: 1px solid #000000;
                        height: 15px;
                        width: 15px;
                        border-radius: 5px;
                        background: @color-blue;
                        cursor: pointer;
                        -webkit-appearance: none;
                        margin-top: -5px;
                        .border-radius(100%);
                      }

                      &::-moz-range-track,
                      &::-ms-track {
                        width: 100%;
                      }
                    }
                  }
                }

                .value-selector {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  gap: 60px;

                  > .button {
                    cursor: pointer;
                    border: none;
                    padding: 9px 30px 8px 30px;
                    color: @color-white;
                    background-color: @color-blue;
                    .font-size(16px, normal);

                    &[data-icon] {
                      padding-left: 10px;
                      padding-right: 10px;
                      background-color: transparent;
                      color: @color-blue;
                      .font-size(32px, 0);
                    }
                  }
                }
              }

              > .image-list {
                width: 100%;
                height: 100%;
                position: relative;

                .image-nav {
                  .nav {
                    position: absolute;
                    top: 50%;
                    .transform (translateY(-50%));;

                    &.prev {
                      left: 0;
                    }

                    &.next {
                      right: 0;
                    }

                    .icon {
                      .font-size(60px, 0);
                      color: @dft-font-color;
                    }
                  }
                }
              }

              > .pin-container {
                color: @dft-font-color;
                width: 64%;

                .pin-field {
                  margin-bottom: 20px;
                  display: flex;
                  gap: 10px;
                  justify-content: space-evenly;

                  .pin {
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 100%;

                    .icon {
                      color: @color-grayl;
                      .font-size(32px, 0);
                      cursor: pointer;
                    }

                    .number {
                      border: 3px solid @color-grayl;
                      height: 80px;
                      width: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      .font-size(22px, normal);
                      font-family: 'ABBvoice-Bold';
                      color: @color-blue;
                      .border-box();
                    }
                  }

                  .selected {
                    background-color: red;
                  }
                }

                .button {
                  cursor: pointer;
                  border: none;
                  padding: 9px 30px 8px 30px;
                  color: @color-white;
                  background-color: @color-blue;
                  .font-size(16px, normal);
                }
              }
            }

            .action {
              padding: 0 5px 5px 5px;
              display: flex;
              gap: 5px;

              .button {
                width: 33.333333333333%;
                cursor: pointer;
                border: none;
                padding: 9px 0 8px 0;
                color: @color-white;
                background-color: @color-blue;
                .font-size(16px, normal);
                .border-box();
                text-align: center;
              }

              .selected {
                background-color: red;
              }
            }
          }

          > .slider {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            > .progress {
              height: ~'calc(100% - (@{navigation-size} * 2))';
              position: relative;
              overflow: hidden;
              padding: 0 5px;

              .bar {
                position: absolute;
                width: 40px;
                background-color: @color-white;
              }
            }
          }

          &[data-touch='false'] {
            background-color: @color-white;

            &[data-bg='white'] {
              background-color: @color-white;
            }

            &[data-padding] {
              background-color: @color-white;
              padding: 15px;
              .border-box();
            }

            > .content {
              > .wrapper {
                height: 100%;

                > .list-item {
                  justify-content: space-evenly;
                  height: 100%;
                  gap: 0;

                  .value {
                    height: auto;
                    font-size: 20px;
                    color: @color-black;
                    height: 50%;

                    &[data-name='top'] {
                      flex-direction: column;
                      height: 100%;
                      justify-content: space-evenly;
                      align-items: stretch;
                      padding: 0;
                      margin: 0;

                      .name {
                        background-color: @color-black;
                        color: @color-white;
                        width: auto;
                        text-align: center;
                        padding: 15px 0 10px 0;
                      }

                      .data {
                        padding: 10px 0;
                      }
                    }
                  }

                  > .button {
                    display: block;
                    padding: 0;
                    height: 50%;
                    background-color: @color-white;

                    .info {
                      height: 100%;

                      .title {
                        height: 100%;
                        display: flex;
                        align-items: center;
                        padding: 0 10px;
                        font-size: 20px;
                        color: @color-black;

                        &.selected {
                          background-color: @color-black;
                          color: @color-white;
                        }
                      }
                    }

                    &[data-check] {
                      position: relative;

                      .check {
                        position: absolute;
                        right: 10px;
                        top: 50%;
                        .transform (translateY(-50%));;

                        .icon {
                          color: @color-black;
                        }

                        &.selected {
                          background-color: transparent;
                          .icon {
                            color: @color-white;
                          }
                        }
                      }
                    }
                  }

                  &[data-type='list-selector'] {
                    > .button {
                      &[data-selected] {
                        background-color: @color-black;
                        color: @color-white;

                        .info {
                          .title {
                            color: @color-white !important;
                          }
                        }
                      }
                    }
                  }
                }

                .pin-container .pin-field {
                  gap: 0;
                  margin-bottom: 0;

                  .pin {
                    .number {
                      height: 40px;
                      .font-size();
                      color: @color-black;
                      border: none;

                      &:before {
                        content: '*';
                        .font-size(22px, normal);
                      }
                    }

                    &.selected {
                      background-color: transparent;

                      .number {
                        .font-size(22px, normal);
                        &:before {
                          display: none;
                        }
                      }
                    }
                  }
                }

                &[data-valign='vertical'] {
                  height: 100%;
                  .border-box();
                }

                > .numeric-selector {
                  padding: 15px 10px;

                  > .title {
                    color: @color-black;
                  }

                  .app-range {
                    > .box-range {
                      .range {
                        &::-webkit-slider-runnable-track {
                          width: 100%;
                          height: 7px;
                          background: @color-white;
                          border: 2px solid @color-black;
                          .border-box();
                        }

                        &::-webkit-slider-thumb {
                          margin-top: -6px;
                          background: @color-black;
                          // width: 5px;
                        }
                      }
                    }
                  }
                }
              }

              > .top {
                background-color: transparent;

                .title {
                  color: @color-black;
                  width: 100%;
                  font-size: 20px;
                }
              }

              &.confirm {
                justify-content: space-evenly;

                > .top {
                  .title {
                    text-align: left;
                    font-size: 20px;
                    padding: 0 10px;
                  }
                }

                .action {
                  border-top: 2px solid @color-black;
                  margin: 0 10px;
                  padding-left: 0;
                  padding-right: 0;

                  .button {
                    background-color: @color-white;
                    color: @color-black;
                    font-size: 17px;
                    padding-left: 0;
                    padding-right: 0;

                    &.selected {
                      background-color: @color-black;
                      color: @color-white;
                    }
                  }
                }
              }
            }

            > .slider {
              padding-left: 5px;

              > .progress {
                border-left: 1px solid @color-black;
                border-right: 1px solid @color-black;

                .bar {
                  background-color: @color-black;
                  width: 3px;
                  left: 4px;
                }
              }
            }
          }

          > .slider > .progress {
            height: 100%;
          }
        }

        .dot-navigation {
          position: absolute;
          top: 15px;
          left: 0;
          width: 100%;
          display: flex;
          justify-content: center;
          gap: 10px;

          .dot {
            width: 10px;
            height: 10px;
            .border-radius(100%);
            background-color: @color-black;

            &[data-selected='true'] {
              background-color: @color-blue;
            }
          }

          &.small {
            top: 10px;

            .dot {
              height: 3px;
              width: 5px;
              .border-radius(0);

              &[data-selected='true'] {
                width: 10px;
                background-color: @color-black;
              }
            }
          }
        }

        .navigation {
          .nav {
            cursor: pointer;
            position: absolute;
            top: 0;
            bottom: 0;
            width: 80px;
            color: @color-white;
            display: flex;
            justify-content: center;
            align-items: center;

            .icon {
              .font-size(30px, 0);
            }

            &.prev {
              left: 0;
            }

            &.next {
              right: 0;
            }

            .hden {
              display: none;
            }
          }
        }

        &[data-over] {
          .font-size(@dft-font-size, @dft-line-height);

          .setting-list {
            background-color: @color-white;
            color: @dft-font-color;
          }
        }
      }
    }

    .led {
      position: absolute;

      .image {
        cursor: pointer;
        display: block;

        &[data-mode='on'] {
          .opacity(1);
        }

        &[data-mode='off'] {
          .opacity(0);
        }

        &[data-mode='2hz'] {
          animation: blink 1s infinite;
          -webkit-animation: blink 1s infinite;
        }

        &[data-mode='0-5hz'] {
          animation: blink 2s infinite;
          -webkit-animation: blink 2s infinite;
        }

        &[data-mode='2b05s2sp'] {
          animation: blink-2b05s2sp 3.5s infinite;
          -webkit-animation: blink-2b05s2sp 3.5s infinite;
        }

        &[data-mode='3b05s3sp'] {
          animation: blink-3b05s3sp 5.5s infinite;
          -webkit-animation: blink-3b05s3sp 5.5s infinite;
        }

        &[data-mode='4b05s4sp'] {
          animation: blink-4b05s4sp 7.5s infinite;
          -webkit-animation: blink-4b05s4sp 7.5s infinite;
        }

        @keyframes blink {
          0% {
            .opacity(1);
          }

          49% {
            .opacity(1);
          }

          50% {
            .opacity(0);
          }

          99% {
            .opacity(0);
          }

          100% {
            .opacity(1);
          }
        }

        @keyframes blink-2b05s2sp {
          0% {
            .opacity(1);
          }

          7.7% {
            .opacity(1);
          }

          15.4% {
            .opacity(0);
          }

          30% {
            .opacity(0);
          }

          30.8% {
            .opacity(1);
          }

          38.5% {
            .opacity(1);
          }

          46.2% {
            .opacity(1);
          }

          47% {
            .opacity(0);
          }

          100% {
            .opacity(0);
          }
        }

        @keyframes blink-3b05s3sp {
          0% {
            .opacity(1);
          }

          4.5% {
            .opacity(1);
          }

          9% {
            .opacity(0);
          }

          13.5% {
            .opacity(0);
          }

          18% {
            .opacity(1);
          }

          22.5% {
            .opacity(1);
          }

          27% {
            .opacity(0);
          }

          31.5% {
            .opacity(0);
          }

          36% {
            .opacity(1);
          }

          40.5% {
            .opacity(1);
          }

          41% {
            .opacity(0);
          }

          100% {
            .opacity(0);
          }
        }

        @keyframes blink-4b05s4sp {
          0% {
            .opacity(1);
          }

          3.3% {
            .opacity(1);
          }

          6.6% {
            .opacity(0);
          }

          9.9% {
            .opacity(0);
          }

          13.2% {
            .opacity(1);
          }

          16.5% {
            .opacity(1);
          }

          19.8% {
            .opacity(0);
          }

          23.1% {
            .opacity(0);
          }

          26.4% {
            .opacity(1);
          }

          29.7% {
            .opacity(1);
          }

          33% {
            .opacity(0);
          }

          36.3% {
            .opacity(0);
          }

          39.6% {
            .opacity(1);
          }

          42.9% {
            .opacity(1);
          }

          45% {
            .opacity(0);
          }

          100% {
            .opacity(0);
          }
        }
      }

      .ac-list {
        position: absolute;
        min-width: 150px;
        z-index: 1;
        top: 25px;
        left: 0;
        background-color: @color-white;
        padding: 8px 0;
        .color-rgba(@color-black, 0.35);
        .shadow(0px 6px 12px @color-rgba);
        visibility: hidden;
        .opacity(0);
        .transition(@dft-transition);

        &.show {
          visibility: visible;
          .opacity(1);
        }

        > .item {
          padding: 8px 13px 8px 16px;
          .font-size(14px, 16px);
          cursor: pointer;

          &[data-selected='true'] {
            color: @color-blue;
          }
        }
      }
    }
  }
}

.app-modal {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .color-rgba(@color-black, 0.7);
  background-color: @color-rgba;
  z-index: 10;

  .wrapper {
    position: relative;
    background-color: @color-white;
    padding: 30px;
    width: 700px;
    max-width: ~'calc(100% - 60px)';
    .border-box();

    .close {
      position: absolute;
      top: 10px;
      right: 10px;
      .font-size(20px, 0);
      cursor: pointer;
    }

    > .header {
      margin-bottom: 15px;

      .title {
        font-family: 'ABBvoice-Bold';
        .font-size(22px, normal);
      }
    }

    > .action {
      margin-top: 20px;
    }
  }
}

.container-toast {
  position: absolute;
  inset: 0;
  z-index: 9;
  .font-size(20px, normal);
  color: @dft-font-color;
  display: flex;
  justify-content: center;
  align-items: center;

  .wrapper {
    width: 70%;
    height: 60%;
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    border: 3px solid @color-blue;
    background-color: @color-white;
    .border-box();

    &[data-alert] {
      border-color: @color-orange;
    }
  }

  &.small {
    .wrapper {
      border-color: @color-black;
      color: @color-black;
      flex-direction: row-reverse;

      &[data-alert] {
        border-color: @color-black;
      }
    }
  }
}

.insight{
  margin-bottom: 20px;
  background-color: @color-grayxxl;
  padding: 20px 30px 15px 30px;
}