@import 'Variables.less';
@import 'Standard/Mixins.less';

@media @media-rd-1400 {
  .device-list {
    > .item {
      .colWidthReset(4, 30px);
      .colWidth(100%, 3, 30px);
    }
  }

  .app-tripunit {
    .settings {
      > .parameters {
        width: 20%;
      }

      > .tabel-settings {
        width: 50%;
      }

      > .chart {
        width: 30%;
      }
    }
  }

  .data-table {
    .table {
      .thead,
      .tbody {
        .row {
          .cel {
            padding: 10px 6px 6px 6px;
            min-width: auto;
          }
        }
      }
    }
  }
}
