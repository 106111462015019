@import 'Variables.less';
@import 'Standard/Mixins.less';

@media @media-rd-tablet {
  .main-title {
    padding-top: 10px;
    .font-size(26px, 30px);

    &:before {
      width: 30px;
      height: 3px;
    }
  }

  .cta {
    a,
    .button {
      &:hover {
        background-color: transparent;
      }

      &[data-border='0'] {
        &:hover {
          border-color: transparent;
        }
      }

      &[data-color='blue'] {
        color: @color-blue;

        &:hover {
          color: @color-blue;
        }
      }
    }
  }

  .app-container {
    > .sidebar {
      position: relative;
      z-index: 1;

      > .content {
        > .show-search {
          display: flex;
          justify-content: center;
          align-items: center;
          position: fixed;
          z-index: 11;
          bottom: 30px;
          right: 20px;
          cursor: pointer;
          width: 50px;
          height: 50px;
          .border-radius(30px);
          background-color: @color-blue;
          .color-rgba(@color-black, 0.35);
          .shadow( 0px 6px 12px @color-rgba);

          .icon {
            position: relative;
            top: 2px;
            color: @color-white;
            .font-size(24px, 0);
          }
        }

        .wrap-tap {
          position: fixed;
          z-index: 11;
          inset: 0;
          .color-rgba(@color-black, 0.5);
          background-color: @color-rgba;
          visibility: hidden;
          .opacity(0);
          .transition(@dft-transition);
        }

        .search {
          position: fixed;
          z-index: 12;
          inset: 0 auto 0 -100%;
          width: 320px;
          max-width: ~'calc(100% - 30px)';
          background-color: @color-white;
          .transition(@dft-transition);
        }

        &.show {
          .wrap-tap {
            visibility: visible;
            .opacity(1);
          }

          .search {
            left: 0;
          }
        }

        .tripmenu {
          > .list-item {
            width: 80px;

            .button {
              &:hover {
                background-color: @color-grayxxl;
              }

              > .content {
                .icon {
                  .font-size(20px, 0);
                }

                .label {
                  .font-size(12px, normal);
                }
              }

              &[data-selected] {
                &:hover {
                  background-color: @color-grayxl;
                }
              }
            }
          }
        }
      }
    }

    > .content {
      padding: 0 10px;

      .main {
        padding: 30px 20px;
      }

      .app-footer {
        > .head {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 10px;
        }

        .wrap-tap {
          position: fixed;
          z-index: 10;
          display: block;
          inset: 0;
          .color-rgba(@color-black, 0.5);
          background-color: @color-rgba;
          visibility: hidden;
          .opacity(0);
          .transition(@dft-transition);
        }

        > .content {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          position: fixed;
          z-index: 11;
          inset: auto 0 -20% 0;
          background-color: @color-white;
          max-height: ~'calc(100% - 70px)';
          min-height: 500px;
          visibility: hidden;
          .opacity(0);
          .border-box();
          .transition(@dft-transition);

          > .close {
            display: block;
            position: absolute;
            top: 20px;
            right: 20px;

            .icon {
              .font-size(24px, 0);
            }
          }

          > .main-title {
            display: block;
            margin-bottom: 40px;
          }

          > .list-item {
            flex-direction: column;
            margin-bottom: 40px;

            .link {
              &:hover {
                color: @color-gray;
              }
            }
          }

          > .copy {
            text-align: center;
            margin: 0;

            br {
              display: none;
            }

            .year {
              margin-left: 5px;
            }
          }
        }

        &.show {
          .wrap-tap {
            visibility: visible;
            .opacity(1);
          }

          > .content {
            bottom: 0;
            visibility: visible;
            .opacity(1);
          }
        }
      }
    }
  }

  .device-list {
    > .item {
      .colWidthReset(3, 20px);
      .colWidth(100%, 2, 20px, 20px);
    }

    &[data-family] {
      > .item {
        .colWidthReset(3, 20px);
        .colWidth(100%, 2, 20px);
      }
    }
  }

  .data-table {
    .table {
      &[data-type='tripunits'],
      &[data-type='library'] {
        display: block;

        .thead {
          display: none;
        }

        .tbody {
          .flex-flow();

          .row {
            border: 1px solid @color-grayxxl;
            padding: 15px 20px;
            .colWidth(100%, 2, 20px);
            .dy-inl-bl();
            .border-box();
            display: flex;
            flex-flow: column;
            position: relative;

            .cel {
              display: block;
              padding: 0;
              border: 0 !important;

              &[data-type='version'] {
                order: 1;
              }

              &[data-type='protection'] {
                order: 2;
                margin-bottom: 10px;
              }

              &[data-type='type'],
              &[data-type='circuit-breaker'],
              &[data-type='application'] {
                .font-size(12px, normal);
              }

              &[data-type='type'] {
                order: 3;
              }

              &[data-type='circuit-breaker'] {
                order: 4;
                margin-bottom: 10px;
              }

              &[data-type='application'] {
                order: 5;
              }

              &[data-type='standard'] {
                position: absolute;
                top: 10px;
                right: 20px;
                background-color: @color-grayxl;
                font-weight: bold;
                padding: 3px 10px 0px 10px;
                .border-radius(10px);
              }

              .tag {
                margin-right: 5px;
                .dy-inl-bl();
              }
            }
          }
        }
      }

      &[data-type='library'] {
        .tbody {
          .row {
            .cel {
              &[data-type='short'] {
                font-weight: bold;
              }
            }
          }
        }
      }
    }
  }

  .app-tripunit {
    .settings {
      display: flex;
      flex-direction: column;
      gap: 25px;

      > .parameters {
        width: 100%;
        margin-bottom: 0;

        > .form {
          > .fieldset {
            display: flex;
            gap: 30px;

            > .item {
              width: 190px;
              margin: 0;

              &[data-type='neutral'],
              &[data-type='parameters'],
              &[data-type='frequency'] {
                .control {
                  margin-top: 7px;
                }
              }
            }
          }
        }
      }

      > .tabel-settings {
        width: 100%;

        .warning {
          padding: 0 15px;
        }
      }

      > .chart {
        width: auto;
        margin-top: 0;
        margin-left: 0;
      }
    }

    .scenario {
      .form {
        width: 100%;
      }

      .scenario-detail {
        .description,
        .box-image {
          width: 100%;
          display: block;
        }

        .description {
          padding: 20px;
        }

        .box-image {
          margin-top: 25px;
          top: auto;
          padding-left: 0;
        }
      }
    }
  }

  .app-modal {
    .wrapper {
      padding: 30px 20px;
    }
  }
}
