html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
audio,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
mark,
menu,
meter,
nav,
output,
progress,
section,
summary,
time,
video {
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  margin: 0;
  padding: 0;
}
body {
  line-height: 1;
}
article,
aside,
dialog,
figure,
footer,
header,
hgroup,
nav,
section,
blockquote {
  display: block;
}
nav ul {
  list-style: none;
}
ol {
  list-style: decimal;
}
ul {
  list-style: disc;
}
ul ul {
  list-style: circle;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: none;
}
ins {
  text-decoration: underline;
}
del {
  text-decoration: line-through;
}
mark {
  background: none;
}
abbr[title],
dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}
input[type=submit],
input[type=button],
button {
  margin: 0 !important;
  padding: 0 !important;
}
input,
select,
a img {
  vertical-align: middle;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}
address {
  font-style: normal;
}
.cf {
  zoom: 1;
}
.cf:before,
.cf:after {
  content: "";
  display: table;
}
.cf:after {
  clear: both;
}
.fl-l {
  float: left;
}
.fl-r {
  float: right;
}
.hden {
  display: none;
}
.pty-box-gry {
  background-color: #ebebeb;
  border: 1px dashed #9b9b9b;
  margin: 10px 5px;
  padding: 5px;
  position: relative;
}
.pty-box-wht {
  background-color: #ffffff;
  border: 1px dashed #9b9b9b;
  margin: 10px 5px;
  padding: 5px;
  position: relative;
}
.pty-mrg-h {
  margin: 0 10px;
}
.pty-mrg-v {
  margin-top: 10px;
}
.pty-pdg {
  padding: 5px;
}
.pty-ttl {
  font-size: 28px;
  line-height: normal;
  color: #777777;
}
.pty-abs {
  font-size: 16px;
  line-height: normal;
  color: #000000;
  font-weight: bold;
}
.pty-btn {
  background-color: #808080;
  font-size: 14px;
  color: #ffffff;
  width: 120px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border: 1px solid #4d4d4d;
  cursor: pointer;
}
a .pty-btn {
  color: #FFFFFF;
  text-decoration: none;
}
.pty-btn a {
  color: #FFFFFF;
  text-decoration: none;
}
.pty-tbx {
  -webkit-appearance: none;
  font-family: Arial;
  font-size: 14px;
  line-height: normal;
  color: #4d4d4d;
  width: 200px;
  height: 26px;
  padding: 0 5px;
  border: 1px solid #808080;
  background-color: #ffffff;
}
.pty-sel {
  color: #ebebeb;
  background-color: #808080;
}
.pty-validation {
  font-size: 11px;
  padding: 5px;
  color: crimson;
}
.pty-alerts {
  border: 1px solid;
  padding: 15px 10px 15px 50px;
  z-index: 1000;
  position: absolute;
}
.pty-info {
  color: #00529B;
  background-color: #BDE5F8;
}
.pty-success {
  color: #4F8A10;
  background-color: #DFF2BF;
}
.pty-warning {
  color: #9F6000;
  background-color: #FEEFB3;
}
.pty-error {
  color: #D8000C;
  background-color: #FFBABA;
}
.pty-chart {
  width: 400px;
  height: 350px;
}
.pty-chart-bar div {
  background-color: dodgerblue;
  text-align: right;
  padding: 5px;
  margin: 1px;
  color: white;
}
.pty-widget-drag {
  background-color: deeppink;
}
.pty-floating-menu {
  width: 300px;
  position: absolute;
  right: 0;
  top: 40px;
  z-index: 10;
}
.pty-header-icon {
  height: 40px;
}
.pty-sidemenu {
  width: 25%;
  height: 100%;
}
.pty-main {
  width: 75%;
  height: 100%;
}
.pty-spinner {
  height: 40px;
  width: 40px;
  background: url("http://kramster.it/img/spinner.gif");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.pty-highlight {
  color: #f08200;
}