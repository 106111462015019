@import 'Variables.less';
@import 'Standard/Mixins.less';

// @media @media-rd-tablet {
@media print {
  body {
    background-color: @color-white;
  }
  .app-header,
  .culture-selector,
  .app-container > .sidebar,
  .app-container > .content .app-footer {
    display: none;
  }

  .app-tripunit {
    .settings {
      display: block;

      .dropdown {
        background-image: none;
        border: none;
        padding: 0;
      }

      .dropdown-label {
        .label {
          top: auto;
        }
      }

      > .parameters {
        width: 100%;
        margin-bottom: 20px;

        .form {
          .fieldset {
            > .item {
              .dy-inl-bl();
              margin: 0 40px 0 0 !important;
              vertical-align: top;

              .data {
                .dy-inl-bl();

                &:not(:last-child) {
                  &:after {
                    content: ', ';
                  }
                }
              }
            }
          }
        }
      }

      .tabel-settings {
        width: 100%;
        margin-bottom: 40px;

        .cel[data-tooltip] {
          display: none;
        }
      }
    }

    .print-note {
      display: block;
    }
  }

  .actions {
    display: none;
  }

  .app-container {
    padding-top: 0;

    > .content {
      padding-right: 0;

      .main {
        padding: 0;
      }
    }
  }

  .wrap-tripunit .tripunit .display .screen .setting-list {
    > .content {
      > .top {
        height: 20px;

        .title {
          width: calc(100% - (20px * 2));
          font-size: 12px;
        }
      }

      > .wrapper {
        height: calc(100% - 20px);

        > .numeric-selector{
          .transform(scale(0.7));
        }

        > .list-item {
          .value {
            .font-size(8px, normal);
            height: 13px;
          }

          > .button {
            height: 27px;
            .font-size(8px, normal);
          }

          &[data-type='list-selector']{
            > .title{
              height: 27px;
              font-size: 12px;
            }
          }
        }

        > .pin-container {
          width: 80%;

          .pin-field {
            margin-bottom: 10px;
            gap: 5px;

            .pin {
              .icon {
                .font-size(22px, 0);
              }

              .number {
                border: 2px solid @color-grayl;
                height: 40px;
                .font-size(16px, normal);
              }
            }

            .selected {
              background-color: red;
            }
          }

          .button {
            cursor: pointer;
            border: none;
            padding: 9px 30px 8px 30px;
            color: @color-white;
            background-color: @color-blue;
            .font-size(16px, normal);
          }
        }
      }
    }

    > .slider > .progress .bar {
      width: 11px;
    }

    .navigation {
      width: 20px;
      height: 20px;

      .icon {
        font-size: 12px;
      }
    }
  }

  .container-toast {
    position: absolute;
    inset: 0;
    z-index: 9;
    .font-size(16px, normal);
    color: @dft-font-color;
    display: flex;
    justify-content: center;
    align-items: center;
  
    .wrapper {
      width: 80%;
      height: 70%;
      padding: 10px;
      gap: 10px;

      .image{
        width: 30px;
        height: auto;
      }  
    }

  }
}

@media print and (orientation: landscape) {
  .wrap-tripunit .tripunit .display .screen .setting-list {
    > .content {
      > .top {
        height: 30px;

        .title {
          width: calc(100% - (30px * 2));
          font-size: 16px;
        }
      }

      > .wrapper {
        height: calc(100% - 30px);

        > .numeric-selector{
          .transform(scale(1));
        }

        > .list-item {
          .value {
            .font-size(12px, normal);
            height: 20px;
          }

          > .button {
            height: 40px;
            .font-size(12px, normal);
          }

          &[data-type='list-selector']{
            > .title{
              height: 40px;
              font-size: 12px;
            }
          }
        }
      }
    }

    > .slider > .progress .bar {
      width: 20px;
    }

    .navigation {
      width: 30px;
      height: 30px;

      .icon {
        font-size: 16px;
      }
    }
  }
}
