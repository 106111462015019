@import "../../Variables.less";
@import "../../Standard/Mixins.less";

@fasticname: "fontello";
@fasticfile: fontello;

@font-face {
    font-family: @fasticname;
    src: url('@{path-vendor}fontello/font/@{fasticfile}.eot');
    src: url('@{path-vendor}fontello/font/@{fasticfile}.eot') format("embedded-opentype"), url('@{path-vendor}fontello/font/@{fasticfile}.woff') format("woff"), url('@{path-vendor}fontello/font/@{fasticfile}.ttf') format("truetype"), url('@{path-vendor}fontello/font/@{fasticfile}.svg') format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

[class^="xt-"]:before, [class*=" xt-"]:before {
    margin-left: 0;
    margin-right: 0;
}
