[class^="xt-"]:before, [class*=" xt-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.xt-alarm-bell:before { content: '\e800'; } /* '' */
.xt-axis-position-hor:before { content: '\e801'; } /* '' */
.xt-axis-position-ver:before { content: '\e802'; } /* '' */
.xt-close:before { content: '\e803'; } /* '' */
.xt-filter:before { content: '\e804'; } /* '' */
.xt-globe:before { content: '\e805'; } /* '' */
.xt-information:before { content: '\e806'; } /* '' */
.xt-led:before { content: '\e807'; } /* '' */
.xt-angle-right:before { content: '\e808'; } /* '' */
.xt-library:before { content: '\e809'; } /* '' */
.xt-numbered-list:before { content: '\e80a'; } /* '' */
.xt-angle-top:before { content: '\e80b'; } /* '' */
.xt-search:before { content: '\e80c'; } /* '' */
.xt-thumbnail-view:before { content: '\e80d'; } /* '' */
.xt-touch:before { content: '\e80e'; } /* '' */
.xt-view:before { content: '\e80f'; } /* '' */
.xt-alert:before { content: '\e810'; } /* '' */
.xt-arrow-left:before { content: '\e811'; } /* '' */
.xt-angle-bottom:before { content: '\e812'; } /* '' */
.xt-angle-left:before { content: '\e813'; } /* '' */
.xt-triangle-bottom:before { content: '\e814'; } /* '' */
.xt-triangle-top:before { content: '\e815'; } /* '' */
.xt-print:before { content: '\e816'; } /* '' */
.xt-reset:before { content: '\e817'; } /* '' */
.xt-download:before { content: '\e818'; } /* '' */
.xt-full:before { content: '\e819'; } /* '' */
.xt-fullout:before { content: '\e81a'; } /* '' */
.xt-smartphone:before { content: '\e81b'; } /* '' */
.xt-settings:before { content: '\e81c'; } /* '' */
.xt-circle-left:before { content: '\e81d'; } /* '' */
.xt-circle-right:before { content: '\e81e'; } /* '' */
.xt-check:before { content: '\e81f'; } /* '' */
.xt-minus:before { content: '\e820'; } /* '' */
.xt-plus:before { content: '\e821'; } /* '' */
