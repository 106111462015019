@import "Standard/Reset.less";
@import "Standard/Standard.less";

@import "../wwwroot/vendors/fontello/css/fontello.css";
@import "Vendors/fontello/styles.less";

@import "Support/Form/form.less";

/*DEV*/
@import "Support/Prototype.less";

@import "App.less";
@import "App.rd-1920.less";
@import "App.rd-1400.less";
@import "App.rd-1200.less";
@import "App.rd-tablet.less";
@import "App.rd-1000.less";
@import "App.rd-smartphone.less";
@import "App.rd-smartphone-landscape.less";

@import "Print.less";